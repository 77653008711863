import React, { useState } from "react";
import api from "../services/api";

export const ProlabContext = React.createContext({});

export const ProlabProvider = ({ children }) => {
  const listPillsProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/pills/category/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createPillProlabApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/insert/pill/learning", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listVideosProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/videos/category/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };
  const showVideoProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/video/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showPillProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/pill/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showPillRandomProlabApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/pill/random/category/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createVideoProlabApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/insert/video/learning", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const updateVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/video/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const updatePillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/pill/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deletePillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/pill/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deleteVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/video/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const deleteSecondaryCategoryVideoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/video/secondary/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const deleteSecondaryCategoryPillApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/pill/secondary/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listDocumentsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/documents/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const deleteDocumentApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/document/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  return (
    <ProlabContext.Provider
      value={{
        listPillsProlabApi,
        createPillProlabApi,
        listVideosProlabApi,
        createVideoProlabApi,
        showPillProlabApi,
        showVideoProlabApi,
        updateVideoApi,
        updatePillApi,
        deleteVideoApi,
        deletePillApi,
        showPillRandomProlabApi,
        deleteSecondaryCategoryVideoApi,
        deleteSecondaryCategoryPillApi,
        listDocumentsApi,
        deleteDocumentApi,
      }}
    >
      {children}
    </ProlabContext.Provider>
  );
};
