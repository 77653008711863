import { useState, useEffect, useRef } from "react";
import api from "../../services/api";
import {
  Box,
  Icon,
  Input,
  Select,
  Text,
  Button,
  Link,
  Stack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";

export default function StandardsDocumentsOrderExpert() {
  const toast = useToast();
  const [inputs, setInputs] = useState([]);
  const fileInputRefs = useRef([]);
  const [expertDocuments, setExpertDocuments] = useState([]);
  const [clientDocuments, setClientDocuments] = useState([]);

  const handleChange = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index].file = event.target.files[0];
    setInputs(newInputs);
  };

  const addInput = () => {
    setInputs([
      ...inputs,
      {
        version: "Versão 1",
        subtype: "Texto",
        file: null,
      },
    ]);
  };

  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, property, value) => {
    const newInputs = [...inputs];
    newInputs[index] = {
      ...newInputs[index],
      [property]: value,
    };
    setInputs(newInputs);
  };

  async function getDocumentsOrder() {
    const editionID = localStorage.getItem("editionID");
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`list/documents/edition/${editionID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setClientDocuments(response.data?.[1]?.client);
      setExpertDocuments(response.data?.[1]?.experts);
    } catch (error) {
      console.error(error);
    }
  }

  const handleDocumentSubmit = async (index) => {
    if (inputs.length == 0) {
      alert(
        "Você não anexou nenhum documento, anexe um documento para adicionar o serviço"
      );
    }

    console.log(inputs);

    const editionID = localStorage.getItem("editionID");

    await Promise.all(
      inputs.map(async (input) => {
        if (input) {
          console.log(input.file);
          const formData = new FormData();
          formData.append("file", input.file);
          formData.append("edition_id", editionID);
          formData.append("subtype", input.subtype);

          const token = localStorage.getItem("token");
          const fileUploadResponse = await api.post(
            "upload/documents/expert/edition",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Adicione o resultado do upload ao array fileUploadResponses
          return fileUploadResponse.data;
        } else {
          // Retorne undefined se nenhum arquivo for selecionado
          return undefined;
        }
      })
    );
    getDocumentsOrder();
    setInputs([]);
  };

  useEffect(() => {
    getDocumentsOrder();
  }, []);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"240px"}
        w={"100%"}
      >
        <Flex
          pb={5}
          borderBottom={"1px solid #D9D9D9"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>Enviar documentos </Box>

          <Button onClick={handleDocumentSubmit}>Salvar</Button>
        </Flex>
        <Box>
          <Stack>
            <Text fontWeight={300} mt={"20px"}>
              Envie arquivos nas extensões de docx. doc. e PDF.
            </Text>
          </Stack>
          <Button
            onClick={addInput}
            bg={"white"}
            border={"1px"}
            borderStyle={"dashed"}
            borderColor={"#DBDBDB"}
            height={"56px"}
            marginTop={"25px"}
          >
            <Icon fontSize={25} color={"blue"}>
              <IoAddCircleOutline />
            </Icon>

            <Box display={"inline-block"} marginLeft={2}>
              <Text fontSize={12} fontWeight={600}>
                Deseja enviar mais um documento?
              </Text>
              <Text fontSize={14} fontWeight={400}>
                Clique para adicionar mais um arquivo
              </Text>
            </Box>
          </Button>
          {inputs.map((input, index) => (
            <Box display={"flex"} alignItems={"center"} key={index}>
              <Box
                marginRight={"20px"}
                mt={5}
                width={"350px"}
                maxWidth={"100%"}
              >
                <Text fontWeight={400} fontSize={"12px"}>
                  Documento
                </Text>
                <Input
                  pt={2}
                  type="file"
                  fontSize={"12px"}
                  onChange={handleChange(index)}
                  ref={(el) => (fileInputRefs.current[index] = el)}
                />
              </Box>

              <Box mt={5} mr={2} width={"150px"}>
                <Text fontWeight={400} fontSize={"12px"}>
                  Subtipo
                </Text>
                <Select
                  value={input.subtype}
                  onChange={(event) =>
                    handleInputChange(index, "subtype", event.target.value)
                  }
                >
                  <option value="Texto">Texto</option>
                  <option value="Cover Letter">Cover Letter</option>
                  <option value="Abstract">Abstract</option>
                  <option value="Figura">Figura</option>
                  <option value="Tabela">Tabela</option>
                  <option value="Referências">Referências</option>
                  <option value="Material suplementar">
                    Material suplementar
                  </option>
                  <option value="Highlights">Bullet points/Highlights</option>
                  <option value="Feedback">Feedback</option>
                </Select>
              </Box>
              {input.subtype != "Feedback" ? (
                <Box marginRight={"20px"} mt={5}>
                  <Text fontWeight={400} fontSize={"12px"}>
                    Versão
                  </Text>
                  <Select
                    width="115x"
                    value={input.version}
                    onChange={(event) =>
                      handleInputChange(index, "version", event.target.value)
                    }
                  >
                    <option value={"Versão 1"}> 1</option>
                    <option value={"Versão 2"}> 2</option>
                    <option value={"Versão 3"}> 3</option>
                    <option value={"Versão 4"}> 4</option>
                    <option value={"Versão 5"}> 5</option>
                    <option value={"Versão 6"}> 6</option>
                    <option value={"Versão 7"}> 7</option>
                    <option value={"Versão 8"}> 8</option>
                    <option value={"Versão 9"}> 9</option>
                    <option value={"Versão 10"}> 10</option>
                    <option value={"Versão 11"}> 11</option>
                    <option value={"Versão 12"}> 12</option>
                    <option value={"Versão 13"}> 13</option>
                    <option value={"Versão 14"}> 14</option>
                    <option value={"Versão 15"}> 15</option>
                    <option value={"Clean Copy"}>Clean Copy</option>
                  </Select>
                </Box>
              ) : (
                ""
              )}

              <Box mt={5}>
                <Icon
                  as={IoTrashOutline}
                  onClick={() => removeInput(index)}
                  fontSize={25}
                  color="red"
                  cursor="pointer"
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        mt={5}
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"240px"}
        w={"100%"}
      >
        <Flex pb={5}>
          <Box>Documentos cliente</Box>
        </Flex>

        {clientDocuments?.map((index) => (
          <Box mt={2} fontSize={"14px"}>
            <Box display={"inline-flex"}>
              <Text fontWeight={"light"}>Documento: </Text>
              <Link
                ml={2}
                fontWeight={"bold"}
                href={
                  "https://api-provatis.provatis.academy/uploads/clients/editions/" +
                  index.filename
                }
                target="_blank"
              >
                {index.filename}
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={"240px"}
        w={"100%"}
        mt={"20px"}
      >
        <Box m={2}>
          <Stack>
            <Text>Documentos Edição de Normas</Text>
          </Stack>
        </Box>
        {expertDocuments?.map((index) => (
          <Box mt={2} fontSize={"14px"} ml={2}>
            <Box display={"inline-flex"}>
              <Text fontWeight={"light"}>Documento: </Text>
              <Link
                ml={2}
                fontWeight={"bold"}
                href={
                  index.filename.startsWith("uploads/expert/editions/")
                    ? "https://api-provatis.provatis.academy/" + index.filename
                    : "https://api-provatis.provatis.academy/uploads/expert/editions/" +
                      index.filename
                }
                target="_blank"
              >
                {index.filename}
              </Link>
            </Box>

            <Box display={"inline-flex"} ml={5}>
              <Text fontWeight={"light"}>Versão: </Text>
              <Text ml={2} fontWeight={"bold"}>
                {" "}
                {index.version}
              </Text>
            </Box>

            <Box display={"inline-flex"} ml={5}>
              <Text fontWeight={"light"}>Subtipo: </Text>
              <Text ml={2} fontWeight={"bold"}>
                {" "}
                {index.subtype}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
