import React, { useState } from "react";
import api from "../services/api";

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const listClientsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("/list/clients", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createClientApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/client", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "/list/experts",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listExpertsAllApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "/list/experts/all",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listExpertsAvaliationApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "get/experts/avaliation",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const getUserInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/show/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const inviteExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/invite/expert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsNotificationsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/notifications/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/create/expert", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const getExpertInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/show/expert/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const updateExpertApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`/update/expert/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const insertScheduleExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("insert/schedule", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listScheduleExpert = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/list/schedule/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const listExpertEditor = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("experts/editors/magazine", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const inviteExpertEditionApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("invite/expert/edition", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  return (
    <UserContext.Provider
      value={{
        listClientsApi,
        createClientApi,
        listExpertsApi,
        inviteExpertApi,
        listExpertsNotificationsApi,
        createExpertApi,
        getExpertInfoApi,
        updateExpertApi,
        getUserInfoApi,
        insertScheduleExpertApi,
        listScheduleExpert,
        listExpertEditor,
        inviteExpertEditionApi,
        listExpertsAvaliationApi,
        listExpertsAllApi,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
