import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Container,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";

export default function Users() {
  const { listExpertsApi, listExpertsAllApi } = useContext(UserContext);
  const [expert, setExperts] = useState();
  const navigate = useNavigate();

  function goToScore(value) {
    localStorage.setItem("expertID", value.userID);
    navigate(`/profile/expert/${value.name}`);
  }
  function goToProfile(value) {
    navigate(`/configuracoes/${value.userID}`);
  }

  async function getExperts() {
    try {
      const clients = await listExpertsAllApi();
      setExperts(clients.data?.data);
      console.log(clients);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getExperts();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        position={"absolute"}
        top={120}
        ml={{ base: 0, md: 300 }}
        minWidth={{ base: 0, md: "75vw" }}
      >
        <Flex display={"inline-block"}>
          <Button colorScheme="green">
            <Link to="/adicionar/usuario"> Adicionar novo experts </Link>
          </Button>
        </Flex>

        <Flex display={"flex"} justifyContent={"center"}>
          <Box mt={"10px"} width={"100%"}>
            <Box>
              <TableContainer>
                <Table variant="striped" colorScheme="green">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Nome</Th>
                      <Th>Email</Th>
                      <Th>Ação</Th>
                    </Tr>
                  </Thead>
                  {expert && (
                    <Tbody>
                      {expert.map((index) => (
                        <Tr key={index.userID}>
                          <Td>{index.userID}</Td>
                          <Td>{index.name}</Td>
                          <Td>{index.email}</Td>
                          <Td>
                            <Button onClick={() => goToScore(index)}>
                              Acessar desempenho
                            </Button>
                          </Td>
                          <Td>
                            <Button onClick={() => goToProfile(index)}>
                              Acessar perfil
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
