import React, { useContext, useEffect, useState } from "react";
import api from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
  useToast,
  Box,
  Flex,
  Button,
  FormControl,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import Select, { components } from "react-select";

import { UserContext } from "../../contexts/User";

export default function CreateUser() {
  const { createExpertApi } = useContext(UserContext);
  const toast = useToast();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nick, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [nationality, setNationality] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState();
  const [profession, setProfession] = useState("");
  const [institution, setInstitution] = useState("");
  const [permission, setPermission] = useState("");
  const [academic, setAcademic] = useState("");
  const [university, setUniversity] = useState("");
  const [maxwords, setMaxwords] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [roleID, setRoleID] = useState(null);

  // Defina as opções disponíveis para se
  const handleInputNameChange = (e) => setName(e.target.value);
  const handleInputNickChange = (e) => setNick(e.target.value);
  const handleInputEmailChange = (e) => setEmail(e.target.value);
  const handleInputCpfChange = (e) => setCpf(e.target.value);
  const handleInputRgChange = (e) => setRg(e.target.value);
  const handleInputNationalityChange = (e) => setNationality(e.target.value);
  const handleInputPhoneChange = (e) => setPhone(e.target.value);
  const handleInputWhatsappChange = (e) => setWhatsapp(e.target.value);
  const handleInputPassword = (e) => setPassword(e.target.value);
  const handleInputAddressChange = (e) => setAddress(e.target.value);
  const handleInputCityChange = (e) => setCity(e.target.value);
  const handleInputCountryChange = (e) => setCountry(e.target.value);
  const handleInputZipcodeChange = (e) => setZipcode(e.target.value);
  const handleInputProfessionChange = (e) => setProfession(e.target.value);
  const handleInputInstitutionChange = (e) => setInstitution(e.target.value);
  const handleInputPermissionChange = (selectedOption) =>
    setPermission(selectedOption.value);
  const handleInputAcademicChange = (selectedOption) =>
    setAcademic(selectedOption.value);
  const handleInputUniversityChange = (e) => setUniversity(e.target.value);
  const handleInputMaxwordsChange = (e) => setMaxwords(e.target.value);
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleInputExpertTypeChange = (selectedOption) =>
    setRoleID(selectedOption.value);

  const handleInputKeywords = (e) => {
    const array = e.target.value.replace(";", "").split(" ");
    setKeywords(array);
  };

  const handleInputStateChange = (selectedOption) => {
    setState(selectedOption.value); // Atualiza o estado 'state' com a opção selecionada
  };

  const createExpert = async () => {
    const data = {
      name: name,
      nickname: nick,
      email: email,
      rg: rg,
      cpf: cpf,
      nationality: nationality,
      phone: phone,
      zipcode: zipcode,
      address: address,
      whatsapp: whatsapp,
      role_id: roleID,
      city: city,
      password: password,
      state: state,
      country: country,
      permission_id: permission,
      institution_name: institution,
      profession: profession,
      standard_editor: permission === 4 ? 1 : 0,
      academic_degree: academic,
      max_words: maxwords,
      keywords: selectedOptions.map((option) => option.value),
    };
    try {
      const newUser = await createExpertApi(data);
      if (newUser.status === 200) {
        toast({
          title: "Usuário Criado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      navigate("/usuarios");
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  };
  const MultiValueComponent = (props) => {
    const { children, innerProps, removeProps } = props;
    return (
      <components.MultiValue {...innerProps}>
        <span
          style={{
            backgroundColor: "gray",
            borderRadius: "4px",
            padding: "2px 4px",
            marginRight: "4px",
          }}
        >
          {children}
        </span>
        <span
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "black",
            borderRadius: "50%",
            padding: "2px",
          }}
          {...removeProps}
        >
          x
        </span>
      </components.MultiValue>
    );
  };

  return (
    <>
      <Navbar />
      <Flex ml={250} display={"flex"} justifyContent={"center"}>
        <Box
          m={"30px"}
          marginBottom={5}
          width={"80=%"}
          boxShadow="xl"
          padding={35}
          borderRadius={15}
          backgroundColor={"white"}
        >
          <Box
            borderBottom={"1px"}
            borderColor={"#DBDBDB"}
            marginBottom={"15px"}
            paddingBottom={"15px"}
          >
            <h1>
              {" "}
              <b> Adicionar um novo Expert </b>
            </h1>
          </Box>
          <h3>Detalhes</h3>
          <Box borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <FormControl
              isRequired
              display={"flex"}
              flexWrap={"wrap"}
              marginTop={"20px"}
              marginBottom={"20px"}
            >
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>Nome Completo</FormLabel>
                <Input
                  type={"name"}
                  placeholder="Nome Completo"
                  onChange={handleInputNameChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>Nick</FormLabel>
                <Input
                  type="name"
                  placeholder={"Nick"}
                  onChange={handleInputNickChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>E-mail</FormLabel>
                <Input
                  placeholder="Digite seu e-mail"
                  onChange={handleInputEmailChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>Telefone</FormLabel>
                <Input
                  placeholder="Telefone"
                  onChange={handleInputPhoneChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>Whatsapp</FormLabel>
                <Input
                  placeholder="WhatsApp"
                  onChange={handleInputWhatsappChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>CPF</FormLabel>
                <Input placeholder="CPF" onChange={handleInputCpfChange} />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel fontWeight={"12px"}>RG</FormLabel>
                <Input placeholder={"RG"} onChange={handleInputRgChange} />
              </Box>
              <Box w={"30%"} marginRight={"20px"}>
                <FormLabel fontWeight={"12px"}>Nacionalidade</FormLabel>
                <Input
                  type="text"
                  placeholder="Nacionalidade"
                  onChange={handleInputNationalityChange}
                />
              </Box>
            </FormControl>
          </Box>

          <Box Box marginTop={"20px"}>
            <h3>Segurança</h3>
          </Box>
          <Box borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <FormControl
              isRequired
              display={"flex"}
              flexWrap={"wrap"}
              marginTop={"20px"}
              marginBottom={"20px"}
            >
              <Box w={"30%"} marginRight={"20px"}>
                <FormLabel>Senha</FormLabel>
                <Input
                  onChange={handleInputPassword}
                  marginRight={"20px"}
                  type="password"
                  placeholder="Senha"
                />
              </Box>

              <Box w={"30%"} marginRight={"20px"}>
                <FormLabel height={"16px"}>Tipo de expert</FormLabel>
                <Select
                  onChange={handleInputExpertTypeChange}
                  options={[
                    { value: 4, label: "Em teste" },
                    { value: 2, label: "Expert Aprovado" },
                  ]}
                ></Select>
              </Box>
              {roleID == 2 || (roleID != null && roleID != 4) ? (
                <Box w={"30%"} marginRight={"20px"}>
                  <FormLabel height={"16px"}>Permissão</FormLabel>
                  <Select
                    onChange={handleInputPermissionChange}
                    options={[
                      { value: 1, label: "Tradutor" },
                      { value: 2, label: "Revisor" },
                      { value: 3, label: "Editor" },
                      { value: 4, label: "Editor de normas" }
                    ]}
                  ></Select>
                </Box>
              ) : (
                " "
              )}
            </FormControl>
          </Box>

          <Box Box marginTop={"20px"}>
            <h3>Endereço</h3>
          </Box>
          <Box borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <FormControl
              isRequired
              display={"flex"}
              flexWrap={"wrap"}
              marginTop={"20px"}
            >
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>CEP</FormLabel>
                <Input placeholder="CEP" onChange={handleInputZipcodeChange} />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Endereço</FormLabel>
                <Input
                  placeholder="Endereço"
                  onChange={handleInputAddressChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Cidade</FormLabel>
                <Input placeholder="Cidade" onChange={handleInputCityChange} />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Estado</FormLabel>
                <Select
                  placeholder="Estado"
                  // Define o valor selecionado com base no estado 'state'
                  options={[
                    { value: "ac", label: "Acre" },
                    { value: "al", label: "Alagoas" },
                    { value: "am", label: "Amazonas" },
                    { value: "ap", label: "Amapá" },
                    { value: "ba", label: "Bahia" },
                    { value: "ce", label: "Ceará" },
                    { value: "df", label: "Distrito Federal" },
                    { value: "es", label: "Espírito Santo" },
                    { value: "go", label: "Goiás" },
                    { value: "ma", label: "Maranhão" },
                    { value: "mt", label: "Mato Grosso" },
                    { value: "ms", label: "Mato Grosso do Sul" },
                    { value: "mg", label: "Minas Gerais" },
                    { value: "pa", label: "Pará" },
                    { value: "pb", label: "Paraíba" },
                    { value: "pr", label: "Paraná" },
                    { value: "pe", label: "Pernambuco" },
                    { value: "pi", label: "Piauí" },
                    { value: "rj", label: "Rio de Janeiro" },
                    { value: "rn", label: "Rio Grande do Norte" },
                    { value: "ro", label: "Rondônia" },
                    { value: "rs", label: "Rio Grande do Sul" },
                    { value: "rr", label: "Roraima" },
                    { value: "sc", label: "Santa Catarina" },
                    { value: "se", label: "Sergipe" },
                    { value: "sp", label: "São Paulo" },
                    { value: "to", label: "Tocantins" },
                  ]}
                  onChange={handleInputStateChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>País</FormLabel>
                <Input
                  w={"30%"}
                  marginRight={"20px"}
                  placeholder="País"
                  onChange={handleInputCountryChange}
                />
              </Box>
            </FormControl>
          </Box>

          <Box Box marginTop={"20px"}>
            <h3>Outras Informações</h3>
          </Box>
          <Box borderBottom={"1px"} borderColor={"#DBDBDB"}>
            <FormControl
              isRequired
              display={"flex"}
              flexWrap={"wrap"}
              marginTop={"20px"}
            >
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Profissão</FormLabel>
                <Input
                  placeholder="Profissão"
                  onChange={handleInputProfessionChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Última Universidade</FormLabel>
                <Input
                  placeholder="Última Universidade "
                  onChange={handleInputUniversityChange}
                />
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Grau de acadêmico</FormLabel>
                <Select
                  options={[
                    { value: "graduacao", label: "Graduação" },
                    { value: "especializacao", label: "Especialização" },
                    { value: "mestrado", label: "Mestrado" },
                    { value: "doutorado", label: "Doutorado" },
                    { value: "posdoutorado", label: "Pós Doutorado" },
                  ]}
                  onChange={handleInputAcademicChange}
                ></Select>
              </Box>
              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Máximo de palavras</FormLabel>
                <Input
                  placeholder="Máximo de palavras"
                  onChange={handleInputMaxwordsChange}
                />
              </Box>

              {/* <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Editor de normas</FormLabel>
                <Select
                  width={"30%%"}
                  onChange={handleInputEditorChange}
                  options={[
                    { value: "sim", label: "Sim" },
                    { value: "nao", label: "Não" },
                  ]}
                ></Select>
              </Box> */}

              <Box w={"30%"} marginRight={"20px"} marginBottom={"20px"}>
                <FormLabel>Palavra Chave</FormLabel>
                <Input
                  fontSize={"12px"}
                  onChange={handleInputKeywords}
                  marginLeft="2%"
                  placeholder="Digite as palavras chave separadas por ; "
                />
              </Box>
            </FormControl>
          </Box>
          <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
            <Button margin={"2%"}>
              {" "}
              <Link to="/usuarios"> Voltar </Link>
            </Button>
            <Button colorScheme={"green"} onClick={() => createExpert()}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
