import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Container,
} from "@chakra-ui/react";

import { UserContext } from "../../../contexts/User";

export default function Clients() {
  const { listClientsApi } = useContext(UserContext);
  const navigate = useNavigate();
  const [clients, setClients] = useState();

  function goToProfile(value) {
    navigate(`/configuracoes/${value.userID}`);
  }

  async function listClients() {
    try {
      const clients = await listClientsApi();
      setClients(clients.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    listClients();
  }, []);

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{ base: 0, md: 300 }}
        minWidth={{ base: 0, md: "75vw" }}
      >
        <Flex display={"flex"}>
          <Button colorScheme="green">
            <Link to="/adicionar/cliente"> Adicionar novo cliente </Link>
          </Button>
        </Flex>

        <Flex display={"flex"} justifyContent={"center"}>
          <Box mt={"10px"} width={"100%"}>
            <Box>
              <TableContainer>
                <Table variant="striped" colorScheme="green">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Nome</Th>
                      <Th>Email</Th>
                      <Th>Ação</Th>
                    </Tr>
                  </Thead>
                  {clients && (
                    <Tbody>
                      {clients.map((index) => (
                        <Tr key={index.userID}>
                          <Td>{index.userID}</Td>
                          <Td>{index.name}</Td>
                          <Td>{index.email}</Td>
                          <Td>
                            {" "}
                            <Button
                              colorScheme="green"
                              onClick={() => goToProfile(index)}
                            >
                              Acessar perfil
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
