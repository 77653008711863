import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Container,
} from "@chakra-ui/react";

import { FiSettings, FiUserCheck } from "react-icons/fi";
import { LuSheet, LuFlaskConical } from "react-icons/lu";
import { Link } from "react-router-dom";
import SettingsExpert from "../../components/Settings/SettingsExpert";
import Agenda from "../../components/Settings/Agenda";

export default function Settings() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <Navbar />
      <Container position={"absolute"} top={120} ml={270} maxWidth={"80vw"}>
        <Box
          bg={"white"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center" w={"100%"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              w={"100%"}
              justifyContent={"space-between"}
              zIndex={2}
            >
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <FiSettings fontSize={"20px"} color="#2AD67E" />
                <Text fontSize={"30px"} fontWeight={"600"}>
                  Configurações
                </Text>
              </Box>
              <Button
                borderRadius={"100px"}
                background={"white"}
                border={"1px"}
                borderColor={"#DBDBDB"}
                color={"#505050"}
                fontWeight={400}
                fontSize={"14px"}
                zIndex={3}
              >
                <Link to="/servicos"> Voltar para serviços </Link>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Container>
      <Box ml={300} maxW={"80vw"}>
        <Box p={2}>
          <Tabs variant="soft-rounded" colorScheme="blackAlpha">
            <Box
              mt={"120px"}
              display={"block"}
              position={"absolute"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <TabList display={"block"} borderBottom={"0"}>
                <Tab
                  _hover={{ backgroundColor: "black", color: "white" }}
                  _selected={{ backgroundColor: "black", color: "white" }}
                  gap={2}
                >
                  {" "}
                  <FiUserCheck /> Dados Cliente
                </Tab>
                <Tab
                  _hover={{ backgroundColor: "black", color: "white" }}
                  _selected={{ backgroundColor: "black", color: "white" }}
                  gap={2}
                >
                  {" "}
                  {/*<LuSheet /> Agenda*/}
                </Tab>
              </TabList>
            </Box>

            {user?.role_id == 1 ? (
              <>
                <TabPanels>
                  <TabPanel>
                    {" "}
                    <SettingsExpert />{" "}
                  </TabPanel>
                  <TabPanel>
                    <Agenda />
                  </TabPanel>
                </TabPanels>
              </>
            ) : user?.role_id == 2 || user?.role_id == 4 ? (
              <>
                <TabPanels>
                  <TabPanel>
                    {" "}
                    <SettingsExpert />{" "}
                  </TabPanel>
                  <TabPanel>
                    <Agenda />
                  </TabPanel>
                </TabPanels>
              </>
            ) : (
              ""
            )}
          </Tabs>
        </Box>
      </Box>
          
    </>
  );
}
