import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Container,
} from "@chakra-ui/react";
import TeachingPills from "../../components/Prolab/TeachingPills";
import VideosProvatis from "../../components/Prolab/VideosProvatis";
import ArticleProvatis from "../../components/Prolab/ArticleProvatis";
import ManualProvatis from "../../components/Prolab/ManualProvatis";
import PillsExpert from "../../components/Prolab/PillsExpert";
import VideosExpert from "../../components/Prolab/VideosExpert";
import ArticleExpert from "../../components/Prolab/ArticleExpert";
import ManualExpert from "../../components/Prolab/ManualExpert";
import { BiLogoReact } from "react-icons/bi";
import { LuClipboardList, LuFilm, LuCoffee } from "react-icons/lu";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { FiBookOpen } from "react-icons/fi";

export default function Prolab() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <Navbar />
      <Container
        position={"absolute"}
        top={120}
        ml={{ sm: 0, md: 270 }}
        maxWidth={{ sm: "100%", md: "80vw" }}
      >
        <Box
          bg={"#000000"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center" w={"80%"}>
            <Box>
              <Text color={"white"} fontSize={"40px"} fontWeight={"600"}>
                Prolab.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Container>
      <Box ml={{ sm: 0, md: "280px" }} maxW={"80vw"}>
        <Box p={2}>
          <Tabs variant="soft-rounded" colorScheme="blackAlpha">
            <Box
              mt={"120px"}
              display={"block"}
              w={"250px"}
              position={"absolute"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Guia do Prolab</Text>
              <TabList display={"block"} borderBottom={"0"}>
                <Tab
                  mt={"1.5px"}
                  fontSize={"14px"}
                  _hover={{ backgroundColor: "black", color: "white" }}
                  _selected={{ backgroundColor: "black", color: "white" }}
                  gap={2}
                >
                  <LuCoffee /> Pílulas de ensino
                </Tab>
                <Tab
                  mt={"1.5px"}
                  fontSize={"14px"}
                  _hover={{ backgroundColor: "black", color: "white" }}
                  _selected={{ backgroundColor: "black", color: "white" }}
                  gap={2}
                >
                  <LuFilm /> Vídeos Provatis
                </Tab>
                <Tab
                  mt={"1.5px"}
                  fontSize={"14px"}
                  _hover={{ backgroundColor: "black", color: "white" }}
                  _selected={{ backgroundColor: "black", color: "white" }}
                  gap={2}
                >
                  <HiOutlineDocumentDuplicate /> Artigos Provatis
                </Tab>
                {user?.role_id != 4 && (
                  <>
                    <Tab
                      mt={"1.5px"}
                      fontSize={"14px"}
                      _hover={{ backgroundColor: "black", color: "white" }}
                      _selected={{ backgroundColor: "black", color: "white" }}
                      gap={2}
                    >
                      <FiBookOpen /> Manual Provatis
                    </Tab>
                  </>
                )}
              </TabList>
            </Box>

            {user?.role_id == 1 ? (
              <>
                <TabPanels>
                  <TabPanel>
                    <TeachingPills />
                  </TabPanel>
                  <TabPanel>
                    <VideosProvatis />
                  </TabPanel>
                  <TabPanel>
                    <ArticleProvatis />
                  </TabPanel>
                  <TabPanel>
                    <ManualProvatis />
                  </TabPanel>
                </TabPanels>
              </>
            ) : user?.role_id == 2 ? (
              <>
                <TabPanels>
                  <TabPanel>
                    <PillsExpert />
                  </TabPanel>
                  <TabPanel>
                    <VideosExpert />
                  </TabPanel>
                  <TabPanel>
                    <ArticleExpert />
                  </TabPanel>
                  <TabPanel>
                    <ManualExpert />
                  </TabPanel>
                </TabPanels>
              </>
            ) : user?.role_id == 4 ? (
              <>
                <TabPanels>
                  <TabPanel>
                    <PillsExpert />
                  </TabPanel>
                  <TabPanel>
                    <VideosExpert />
                  </TabPanel>
                </TabPanels>
              </>
            ) : (
              ""
            )}
          </Tabs>
        </Box>
      </Box>
          
    </>
  );
}
