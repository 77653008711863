import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/Login/ForgetPassword";
import Home from "./pages/Home/Home";
import Clients from "./pages/User/Client/Clients";
import Users from "./pages/User/Users";
import CreateUser from "./pages/User/CreateUser";
import Orders from "./pages/Order/Orders";
import CreateClient from "./pages/User/Client/CreateClient";
import CreateOrder from "./pages/Order/CreateOrder";
import Order from "./pages/Order/Order";
import { AuthContext } from "./contexts/Auth";
import HomeExpert from "./pages/Home/HomeExpert";
import OrderExpert from "./pages/Order/OrderExpert";
import UserScore from "./pages/Score/UserScore";
import MyPerformance from "./pages/Performance/MyPerformance";
import Prolab from "./pages/Prolab/Prolab";
import TeachingPills from "./components/Prolab/TeachingPills";
import Cadastro from "./pages/Cadastro/AtualizacaoCadastro";
import EditionsServices from "./pages/Order/EditionsServices";
import DetailEdition from "./pages/Order/DetailEdition";
import EditionsServicesExpert from "./pages/Order/EditionsServiceExpert";
import DetailEditionExpert from "./pages/Order/DetailEditionExpert";

import Settings from "./pages/Settings/Settings";
import Payments from "./pages/Payments/Payments";
import PaymentsVoucher from "./pages/Payments/PaymentVoucher";
import PaymentsExpert from "./pages/Payments/PaymentsExpert";
import VoucherExpert from "./pages/Payments/VoucherExpert";
import CadastroExpert from "./pages/Cadastro/Cadastro";
import Budget from "./pages/Budget/Budget";
import Budgets from "./pages/Budget/Budgets";

function Router() {
  const Private = ({ children }) => {
    const { user, authenticated } = useContext(AuthContext);
    const token = localStorage.getItem("token");
    if (token === null || user === false) {
      return <Navigate to="/" />;
    }

    return children;
  };

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      {/* <Route exact path="/cadastro" element ={<CadastroExpert/>} /> */}
      <Route
        path="/profile/expert/:slug"
        element={
          <Private>
            <MyPerformance />
          </Private>
        }
      />
      <Route path="/recuperar-senha" element={<ForgetPassword />} />
      <Route
        path="/home"
        element={
          <Private>
            <Home />
          </Private>
        }
      />
      <Route
        path="/home/:slug"
        element={
          <Private>
            <HomeExpert />
          </Private>
        }
      />
      <Route
        path="/clientes"
        element={
          <Private>
            <Clients />
          </Private>
        }
      />
      <Route
        path="/adicionar/cliente"
        element={
          <Private>
            <CreateClient />
          </Private>
        }
      />
      <Route
        path="/usuarios"
        element={
          <Private>
            <Users />
          </Private>
        }
      />

      <Route
        path="adicionar/usuario"
        element={
          <Private>
            <CreateUser />
          </Private>
        }
      />
      <Route />
      <Route
        path="/servicos"
        element={
          <Private>
            <Orders />
          </Private>
        }
      />

      <Route
        path="/adicionar/servico/:type"
        element={
          <Private>
            <CreateOrder />
          </Private>
        }
      />
      <Route
        path="/servico/:id/:type"
        element={
          <Private>
            <Order />
          </Private>
        }
      />
      <Route
        path="expert/servico/:id/:type"
        element={
          <Private>
            <OrderExpert />
          </Private>
        }
      />

      <Route
        path="/editions/servicos"
        element={
          <Private>
            <EditionsServices />
          </Private>
        }
      />
      <Route
        path="/editions/servicos/expert"
        element={
          <Private>
            <EditionsServicesExpert />
          </Private>
        }
      />
      <Route
        path="/edition/service/:slug"
        element={
          <Private>
            <DetailEdition />
          </Private>
        }
      />
      <Route
        path="/expert/edition/service/:slug"
        element={
          <Private>
            <DetailEditionExpert />
          </Private>
        }
      />
      <Route
        path="/pagamentos"
        element={
          <Private>
            <Payments />
          </Private>
        }
      />
      <Route
        path="pagamentos/comprovante/:id"
        element={
          <Private>
            <PaymentsVoucher />
          </Private>
        }
      />
      <Route
        path="/pagamentos/expert"
        element={
          <Private>
            <PaymentsExpert />
          </Private>
        }
      />
      <Route
        path="/pagamentos/comprovantes/expert"
        element={
          <Private>
            <VoucherExpert />
          </Private>
        }
      />

      <Route
        path="/prolab"
        element={
          <Private>
            <Prolab />
          </Private>
        }
      />

      <Route
        path="/prolab/adm"
        element={
          <Private>
            <TeachingPills />
          </Private>
        }
      />
      <Route
        path="/configuracoes/:id"
        element={
          <Private>
            <Settings />
          </Private>
        }
      />

      <Route path="criar/orcamento" element={<Budget />} />
      <Route path="/orcamentos" element={<Budgets />} />
    </Routes>
  );
}

export default Router;
