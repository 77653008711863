import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import DetailsOrder from "../../components/Order/DetailOrder";
import StepsOrder from "../../components/Order/StepOrder";
import { OrderContext } from "../../contexts/Order";
import DocumentsOrder from "../../components/Order/DocumentsOrder";
import FeedbackOrder from "../../components/Order/FeedbackOrder";
import DetailsOrderExpert from "../../components/Order/DetailOrderExpert";
import DocumentsOrderExpert from "../../components/Order/DocumentsOrderExpert";
import ChatExpert from "../../components/Order/ChatExpert";
import { useParams, useNavigate } from "react-router-dom";
import { useToast }from "@chakra-ui/react";

export default function OrderExpert() {
  const { getOrderInfo } = useContext(OrderContext);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  console.log(userInfo?.expertInfo?.permission_id);
  const [order, setOrder] = useState();
  const [stepBox, setStepBox] = useState(false);
  const [detailsBox, setDetailBox] = useState(false);
  const { type, id: orderID } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  async function getOrderInfos() {
    const orderID = localStorage.getItem("orderID");
    try {
      const orderInfoApi = await getOrderInfo(orderID);
      // console.log(orderInfoApi);
      setOrder(orderInfoApi.data?.[0]);
    } catch (error) {
      navigate(`/servicos`);
      toast({
        title: "Você não participou desse serviço ",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.log(error);
    }
  }

  useEffect(() => {
      localStorage.setItem("orderID", orderID);
      console.log("Order ID atualizado:", orderID);
  }, [orderID]);

  useEffect(() => {
    getOrderInfos();
  }, []);
  
  return (
    <>
      <Navbar />

      {order && (
        <Box ml={300} maxWidth={"1000px"}>
          <Box p={2}>
            <Tabs variant="soft-rounded" colorScheme="green">
              <Box
                mt={4}
                display={"block"}
                position={"absolute"}
                left={"-20px"}
                backgroundColor={"#ffff"}
                p={8}
                borderRadius={"15px "}
                boxShadow={"0px 7px 16px 4px #EAEAEA"}
              >
                <TabList display={"block"} borderBottom={"0"}>
                  <Tab
                    marginBlock={"1rem"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                  >
                    Detalhes
                  </Tab>
                  <Tab
                    marginBlock={"1rem"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                  >
                    Documentos
                  </Tab>
                  {type === "geral" && userInfo?.expertInfo?.permission_id !== 4 && (
                    <Tab
                      marginBlock={"1rem"}
                      _hover={{ backgroundColor: "black", color: "white" }}
                      _selected={{ backgroundColor: "black", color: "white" }}
                    >
                      Feedbacks
                    </Tab>
                  )}
                  <Tab
                    marginBlock={"1rem"}
                    _hover={{ backgroundColor: "black", color: "white" }}
                    _selected={{ backgroundColor: "black", color: "white" }}
                  >
                    Chat Expert
                  </Tab>
                </TabList>
              </Box>

              <TabPanels>
                <TabPanel>
                  <DetailsOrderExpert typeOrder={type} />
                </TabPanel>
                <TabPanel>
                  <DocumentsOrderExpert typeOrder={type} order={order} />
                </TabPanel>
                {type === "geral" && userInfo?.expertInfo?.permission_id !== 4 ? (
                  <TabPanel>
                    <FeedbackOrder />
                  </TabPanel>
                ) : (
                  <></>
                )}
                <TabPanel>
                  <ChatExpert />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
}
