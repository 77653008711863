import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  RadioGroup,
  useToast,
  Textarea,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";

export default function FeedbackOrder() {
  const orderID = localStorage.getItem("orderID");
  const order = JSON.parse(localStorage.getItem("order"));
  const toast = useToast();
  const {
    feedbackQuestionsApi,
    sendFeedbackApi,
    getOrderFeedbackApi,
    showExpertOrderFeedbackApi,
  } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [positiveFeedback, setPositiveFeedback] = useState("");
  const [negativeFeedback, setNegativeFeedback] = useState("");
  const [feedbacksStatus, setFeedbacksStatus] = useState([]);
  const [expertFeedback, setExpertFeedback] = useState("");
  const [qualityText, setQualityText] = useState();
  const [qualityRevision, setQualityRevision] = useState();
  const [isExpertFeedbackModalOpen, setExpertFeedbackModalOpen] =
    useState(false);

  const userInfo = JSON.parse(localStorage.getItem("user"));

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  function areAllQuestionsAnswered() {
    const questionIds = Object.keys(questions);
    const answeredQuestionIds = Object.keys(answers);
    return questionIds.every((questionId) =>
      answeredQuestionIds.includes(questionId)
    );
  }

  async function getQuestions() {
    try {
      const response = await feedbackQuestionsApi();
      setQuestions(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFeedbacksStatus() {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await getOrderFeedbackApi(orderID);
      setFeedbacksStatus(response.data?.[0]);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getFeedbacksStatus();
    getQuestions();
  }, []);

  const handleAnswerChange = (question, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: value, // Armazena a resposta da pergunta específica
    }));
  };

  const handleSubmit = async () => {
    setLoading(true); // Ativar o overlay de carregamento
    onClose();
    if (
      !orderID ||
      !Object.values(answers).every((value) => value !== '') ||
      !positiveFeedback ||
      !negativeFeedback ||
      !qualityText ||
      !qualityRevision
    ) {
      setLoading(false);
      toast({
        title: "Por favor, preencha todos os campos obrigatórios.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const values = Object.entries(answers).map(([questionId, value]) => ({
      question_id: parseInt(questionId),
      value: parseInt(value),
    }));

    const data = {
      order_id: orderID,
      values: values,
      positives: positiveFeedback,
      negatives: negativeFeedback,
      quality: qualityText,
      qualityRevision: qualityRevision,
    };
    setExpertFeedbackModalOpen(false);
    try {
      const inviteExpert = await sendFeedbackApi(data);
      if (inviteExpert.status === 200) {
        toast({
          title: "Feedback Enviado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        clearFields();
        getFeedbacksStatus();
        getQuestions();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false); // Desativar o overlay de carregamento, independentemente do resultado da requisição
    }
  };

  // Function to open the Expert Feedback Modal
  const openExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(true);
  };

  // Function to close the Expert Feedback Modal
  const closeExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(false);
    clearFields();
  };

  const clearFields = () => {
    setAnswers({});
    setPositiveFeedback("");
    setNegativeFeedback("");
    setQualityText("");
    setQualityRevision("");
  };

  async function showFeedbackUser(userID) {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await showExpertOrderFeedbackApi(userID, orderID);
      setExpertFeedback(response.data?.["feedbacksQuestions"]);
      setQualityText(response.data?.["quality"]?.value || 0);
      setQualityRevision(response.data?.["revision"]?.value || 0);
      openExpertFeedbackModal();
    } catch (error) {
      console.log(error);
    }
    /*** */
  }

  const hideFeedbackButton = () => {
    let result = true;

    if (
      userInfo &&
      (userInfo.permission?.permission !== "Revisor" &&
        userInfo.permission?.permission !== "Editor")
    ) {
      return false;
    }

    if (feedbacksStatus && userInfo.permission?.permission === "Revisor") {
      for (let index = 0; index < feedbacksStatus.length; index++) {
        const feedback = feedbacksStatus[index];

        if (
          (feedback.translator_feedback === 1 &&
            userInfo &&
            userInfo.permission?.permission === "Revisor") 
        ) {
          result = false;
          break;
        }
      }
    }

    if (feedbacksStatus && userInfo.permission?.permission === "Editor") {
      if (
        (feedbacksStatus.translator_feedback === 1 &&
          userInfo &&
          userInfo.permission?.permission === "Revisor") ||
        (feedbacksStatus.revisor_feedback === 1 &&
          userInfo &&
          userInfo.permission?.permission === "Editor")
      ) {
        result = false;
      }
    }

    return result;
  };

  return (
    <>
      <div>
        {/* Seu overlay de carregamento */}
        {loading && <div style={overlayStyle}>Enviando Feedback...</div>}

        {/* O restante do seu componente */}
        {/* ... */}
      </div>

      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: "180px", // Define a margem em telas maiores (md)
        }}
        maxWidth={"100%"}
      >
        <Flex pb={5}>
          <Box>Feedbacks </Box>
        </Flex>
        <Text>
          {" "}
          {order?.expert_test_translation == 1
            ? "A Tradução deste serviço foi realizada por um expert em avaliação"
            : ""}
        </Text>
        {hideFeedbackButton() ? (
          <>
            <Box mt={5}>
              <Button onClick={onOpen}>Realizar Feedback</Button>
            </Box>
          </>
        ) : null}
      </Box>

      {feedbacksStatus !== null ? (
        <>
          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={{
              base: 0, // Remove a margem em telas menores (base)
              md: "180px", // Define a margem em telas maiores (md)
            }}
            maxWidth={"100%"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Td>Expert</Td>
                  <Td>Status feedback</Td>
                  <Td>Feedback</Td>{" "}
                  {/* Adicionei um cabeçalho para a coluna de Feedback */}
                </Tr>
              </Thead>
              <Tbody>
                {feedbacksStatus.length > 0 ? (
                  feedbacksStatus.map((index) => (
                    <Tr key={index.id}>
                      {" "}
                      {/* <-- Opening <Tr> tag */}
                      <Td>
                        {index.translator === 1
                          ? "Tradutor"
                          : index.revisor === 1
                          ? "Revisor"
                          : ""}
                      </Td>
                      <Td>
                        {index.translator === 1
                          ? index.translator_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : index.revisor === 1
                          ? index.revisor_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : ""}
                      </Td>
                      {index.translator_feedback === 1 ||
                      index.revisor_feedback === 1 ? (
                        <Td>
                          <Button
                            onClick={() =>
                              showFeedbackUser(index.expert_id_receive_feedback)
                            }
                          >
                            Ver Feedback
                          </Button>
                        </Td>
                      ) : (
                        " "
                      )}
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    {" "}
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? "Tradutor"
                        : feedbacksStatus.revisor === 1
                        ? "Revisor"
                        : ""}
                    </Td>
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? feedbacksStatus.translator_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : feedbacksStatus.revisor === 1
                        ? feedbacksStatus.revisor_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : ""}
                    </Td>
                    {/* Renderizar o botão "Ver Feedback" somente se translator_feedback ou revisor_feedback for igual a 1 */}
                    {feedbacksStatus.translator_feedback === 1 ||
                    feedbacksStatus.revisor_feedback === 1 ? (
                      <Td>
                        <Button
                          onClick={() =>
                            showFeedbackUser(
                              feedbacksStatus.expert_id_receive_feedback
                            )
                          }
                        >
                          Ver Feedback
                        </Button>
                      </Td>
                    ) : (
                      ""
                    )}
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </>
      ) : (
        // Renderizar um espaço reservado ou estado de carregamento enquanto aguarda os dados
        ""
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback de Desempenho</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.values(questions).map((question) => (
              <>
                <FormControl key={question.question_id} mb={4}>
                  <Text fontWeight={"bold"}>{question.category}</Text>
                  <div
                    fontSize={"14px"}
                    dangerouslySetInnerHTML={{ __html: question.question }} // Renderiza HTML usando dangerouslySetInnerHTML
                  />
                  <Tooltip
                    label={
                      <div
                        dangerouslySetInnerHTML={{ __html: question.help }}
                      />
                    }
                    placement="top-start"
                  >
                    <Text
                      as="span"
                      fontSize="sm"
                      color="gray.500"
                      ml={2}
                      mb={5}
                    >
                      info
                    </Text>
                  </Tooltip>

                  <RadioGroup
                    mt={5}
                    onChange={(value) =>
                      handleAnswerChange(question.question_id, value)
                    }
                    value={answers[question.question_id]}
                  >
                    <Stack direction="row">
                      <Radio value="1">1</Radio>
                      <Radio value="2">2</Radio>
                      <Radio value="3">3</Radio>
                      <Radio value="4">4</Radio>
                      <Radio value="5">5</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </>
            ))}

            <FormControl
              my={5}
              onChange={(e) => setQualityText(e.target.value)}
              value={qualityText}
            >
              <Text fontWeight={"bold"}> Qualidade Geral do Texto</Text>
              <RadioGroup>
                <Stack direction="row">
                  <Radio value="1">1</Radio>
                  <Radio value="2">2</Radio>
                  <Radio value="3">3</Radio>
                  <Radio value="4">4</Radio>
                  <Radio value="5">5</Radio>
                  <Radio value="6">6</Radio>
                  <Radio value="7">7</Radio>
                  <Radio value="8">8</Radio>
                  <Radio value="9">9</Radio>
                  <Radio value="10">10</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            {order?.plan_id !== 1 ? (
              <FormControl
                my={5}
                onChange={(e) => setQualityRevision(e.target.value)}
                value={qualityRevision}
              >
                <Text fontWeight={"bold"}> Qualidade Revisão por pares</Text>
                <RadioGroup>
                  <Stack direction="row">
                    <Radio value="1">1</Radio>
                    <Radio value="2">2</Radio>
                    <Radio value="3">3</Radio>
                    <Radio value="4">4</Radio>
                    <Radio value="5">5</Radio>
                    <Radio value="6">6</Radio>
                    <Radio value="7">7</Radio>
                    <Radio value="8">8</Radio>
                    <Radio value="9">9</Radio>
                    <Radio value="10">10</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            ) : (
              ""
            )}
            <FormControl>
              <FormLabel>Pontos positivos na revisão deste Expert?</FormLabel>
              <Textarea
                onChange={(e) => setPositiveFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
            <FormControl>
              <FormLabel>
                Pontos que podem melhorar na revisão deste Expert?
              </FormLabel>
              <Textarea
                onChange={(e) => setNegativeFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Enviar Respostas
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {expertFeedback && (
        <Modal
          isOpen={isExpertFeedbackModalOpen}
          onClose={closeExpertFeedbackModal}
        >
          <ModalOverlay />
          <ModalContent width={"900px"} maxWidth={"100%"}>
            <ModalHeader>FeedBack Expert</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl my={5}>
                <Text fontWeight={"bold"}> Qualidade Geral do Texto</Text>
                <RadioGroup isDisabled value={qualityText}>
                  <Stack direction="row">
                    {Array.from({ length: 10 }, (_, n) => n + 1).map((i) => (
                      <Radio key={i} value={i}>
                        {i}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>

              {qualityRevision && (
                <FormControl my={5}>
                  <Text fontWeight={"bold"}>Qualidade Revisão por Pares</Text>
                  <RadioGroup isDisabled value={qualityRevision}>
                    <Stack direction="row">
                      {Array.from({ length: 10 }, (_, n) => n + 1).map((i) => (
                        <Radio key={i} value={i}>
                          {i}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}

              {expertFeedback.map((feedbackItem) => (
                <FormControl key={feedbackItem.id} mb={4}>
                  <div
                    mb={4}
                    fontSize={"14px"}
                    fontWeight={"800"}
                    dangerouslySetInnerHTML={{ __html: feedbackItem.question }} // Renderiza HTML usando dangerouslySetInnerHTML
                  />
                  <Tooltip
                    label={
                      <div
                        dangerouslySetInnerHTML={{ __html: feedbackItem.help }}
                      />
                    }
                    placement="top-start"
                  >
                    <Text
                      as="span"
                      fontSize="sm"
                      color="gray.500"
                      ml={2}
                      mb={5}
                    >
                      info
                    </Text>
                  </Tooltip>

                  {typeof feedbackItem.value === "number" ? ( // Verifica se é um número
                    <RadioGroup
                      mt={5}
                      value={feedbackItem.value.toString()} // Converte para string
                      isDisabled // Define o RadioGroup como desabilitado
                    >
                      <Stack direction="row">
                        <Radio value="1" isDisabled>
                          1
                        </Radio>
                        <Radio value="2" isDisabled>
                          2
                        </Radio>
                        <Radio value="3" isDisabled>
                          3
                        </Radio>
                        <Radio value="4" isDisabled>
                          4
                        </Radio>
                        <Radio value="5" isDisabled>
                          5
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  ) : (
                    <Text
                      fontWeight="bold"
                      color="blue"
                      // Você pode definir estilos CSS personalizados aqui
                    >
                      {feedbackItem.value}
                    </Text>
                  )}
                </FormControl>
              ))}
            </ModalBody>
          </ModalContent>
          {/* ... Existing code ... */}
        </Modal>
      )}
    </>
  );
}
